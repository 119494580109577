import { Route, Routes } from 'react-router-dom';
import './App.css';
import ClientRegister from './component/ClientRegister';
import Addtesttoclient from './pages/Addtesttoclient';
import Assigncredits from './pages/Assigncredits';
import Assigntest from './pages/Assigntest';
import Bookingdetails from './pages/Bookingdetails';
import CancelTest from './pages/CancelTest';
import ClientTestwisedata from './pages/ClientTestwisedata';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import OnBoardingData from './pages/OnBoardingData';
import Outsourcepage from './pages/Outsourcepage';
import Testwisedata from './pages/TestWisedata';
import Testwiseregistrationdata from './pages/Testwiseregistrationdata';
import Totalbooking from './pages/Totalbooking';

function App() {
  return (
    <div className="App">
    
      <Routes>
      <Route path="/login" element={<Login/>} />
        
       
        <Route path="/forgot-password" element={<ForgotPassword/>} />
        <Route path="/" element={<Totalbooking/>} />
        <Route path="/onboardclient" element={<ClientRegister/>} />
        <Route path='/total-booking' element={<Totalbooking/>}/>
        <Route path='/test-data' element={<Testwisedata/>}/>
        <Route path='/test-data/registration' element={<Testwiseregistrationdata/>}/>
        <Route path='/test-data-clientwise' element={<ClientTestwisedata/>}/>
        <Route path='/onboareding-data' element={<OnBoardingData/>}/>
        <Route path='/bookingdetail' element={<Bookingdetails/>}/>
        <Route path='/assigncredits' element={<Assigncredits/>}/>
        <Route path='/cancel-test' element={<CancelTest/>}/>
       <Route path='/assigntest' element={<Assigntest/>}/>
       <Route path='/testdetails' element={<Addtesttoclient/>}/>
       {/* <Route path='/testdetailslab' element={<Changelabwise/>}/> */}
       <Route path='/outsource/registration' element={<Outsourcepage/>}/>
      </Routes>
    </div>
  );
}

export default App;
