import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../component/Navbar';
import "./Assingcred.css";

function Assigncredits() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedLab, setSelectedLab] = useState(null);
  const [partnerId, setPartnerId] = useState('');
  const [productInfo, setProductInfo] = useState('');
  const [amount, setAmount] = useState('');
  const [transactionType, setTransactionType] = useState('');
  const [reason, setReason] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [payuid, setpayuid] = useState();

  const handleSearch = async (searchTerm) => {
    try {
      const response = await axios.get(`https://apiv2.labridge.in/admin/lab/${searchTerm}`);
      setName(response.data.labName);
    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };

  const handleSearchemail = async (searchTerm) => {
    try {
      const response = await axios.get(`https://apiv2.labridge.in/admin/labs/${searchTerm}`);
      setName(response.data.data[0].labName);
      setMobile(response.data.data[0].mobile);
      setPartnerId(response.data.data[0]._id);
    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };

  const handleAssignCredit = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://apiv2.labridge.in/admin/assigncredit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          partner_id: partnerId,
          amount: amount,
          transactiontype: transactionType,
          reason: reason,
        }),
      });

      const data = await response.json();

      if (data.success) {
        alert("Credit added");
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckPayment = async () => {
    try {
      const response = await axios.get(`https://apiv2.labridge.in/admin/payustatuscheck/${payuid}`);
      const data = response.data;
      console.log(data.data);
      if (data.success) {
        setPaymentData(data.data);
      } else {
        alert('No record found');
      }
    } catch (error) {
      console.error('Error checking payment:', error);
    }
  };

  useEffect(() => {
    const isLoggedIn = JSON.parse(localStorage.getItem('loginData'));
    if ( !isLoggedIn.access.includes("4")) {
      navigate('/login');
    }
  }, []);

  return (
    <div>
      <div className="wrapper">
        <Navbar />
        <div id="content">
          <div>
            <center>
              <h2>Credit allotment</h2>
            </center>
            <div className="assign-credit-container">
              <div className="input-group">
                <label>
                  Partner ID:
                  <input
                    type="text"
                    className="input-2"
                    value={partnerId}
                    onChange={(e) => {setPartnerId(e.target.value); handleSearch(e.target.value); }}
                  />
                </label>
                <p>{name}</p>
              </div>
              <center><b>OR</b></center>
              <div className="input-group">
                <label>
                  Email:
                  <input
                    type="text"
                    className="input-2"
                    value={email}
                    onChange={(e) => {setEmail(e.target.value); handleSearchemail(e.target.value); }}
                  />
                </label>
                <p>{name}</p>
              </div>
              <div className="input-group">
                <label>
                  Transaction Type:
                  <select
                    className="input-2"
                    value={transactionType}
                    onChange={(e) => setTransactionType(e.target.value)}
                  >
                    <option value="">Select Transaction Type</option>
                    <option value="Money Added">Money Added</option>
                    <option value="Money Refunded">Money Refunded</option>
                    <option value="Money Alloted">Money Alloted</option>
                  </select>
                </label>
              </div>
              <div className="input-group">
                <label>
                  Amount:
                  <input
                    type="text"
                    className="input-2 "
                    value={amount}

                    onChange={(e) => setAmount(e.target.value)}
                  />
                </label>
              </div>
              <div className="input-group">
                <label>
                  Transaction Reason:
                  <input
                    type="text"
                    className="input-2"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </label>
              </div>
              <div className="button-group">
                <button className="btn5" onClick={handleAssignCredit}>
                  {loading ? 'Assigning Credit...' : 'Assign Credit'}
                </button>
                <button className="btn5 ms-2" onClick={() => setShowModal(true)}>
                  Check Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <div>
              <label>
                PayUID:
                <input
                  type="text"
                  value={payuid}
                  onChange={(e) => setpayuid(e.target.value)}
                />
              </label>
              <button onClick={handleCheckPayment}>Search</button>
            </div>
            {paymentData && (
              <div>
                <p>Amount: {paymentData[0].amt}</p>
                <p>Status: {paymentData[0].status}</p>
                <p>Bank Ref Number: {paymentData[0].bankrefnum}</p>
                <p>Product Info: {paymentData[0].productinfo}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Assigncredits;
